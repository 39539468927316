<template>
  <div>
    <swiper ref="mySwiper" v-if="list.length > 0" class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item,index) in list" :key="index + 1">
        <img :src="item.url" />
        <div class="img-text" v-if="isText">{{ item.name }}</div>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'ImageSwiper',
  components: { 
    Swiper,
    SwiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isText: {
      type: Boolean,
      default: true
    },
    slideChangeTransitionStart: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      swiperOption: {
        on: {
          slideChangeTransitionStart : () => {
            this.slideChangeTransitionStart(this.$refs.mySwiper.$swiper.activeIndex)
          }
        },
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 1000 * 10,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created() {
    
  },
  methods:{
    onSwiper(swiper) {
      console.log(swiper)
    }
  }
}
</script>

<style scoped>
  .swiper-button-prev, .swiper-button-next {
    color:white
  }
  .swiper-slide img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 0px;
  }
  .img-text {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 45px;
    line-height: 35px;
    /* text-indent: 50px; */
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: rgba(0,0,0,.5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 25px;
  }
  .swiper-container >>> .swiper-pagination-bullet-active{
    background: #FE6500!important;
  }
  .swiper-container >>> .swiper-pagination-bullet{
    background: #fff;
    opacity: 1;
  }
</style>
