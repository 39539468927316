<template>
  <div style="width: 100%;height: 100%;">
    <div class="imgs">
      <audio :src="audioSrc" id="introduce-audio" :autoplay="audioAutoplay"></audio>
      <image-swiper :list="bzt" :isText="false" :slide-change-transition-start="slideChange"/>
      <img id="center-sound-img" :src="centerSoundImg" @click="playAudio" v-if="bzt.length > 0">
    </div>
    <div class="info">
      <div class="content">
        <div class="title" v-html="bzt[activeIndex].name"></div>
        <div class="detail" v-html="bzt[activeIndex].content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageSwiper from '@/components/image-swiper'
import { getVideoImageData } from '@/api/video-image.js'

export default {
  components: {
    ImageSwiper
  },
  data() {
    return {
      activeIndex: 0,
      audioSrc: '',
      audioAutoplay: false,
      centerSoundImg: require('@/assets/img/video/sound-play.png'),
      bzt: [{
        name:'',
        content: ''
      }]
    }
  },
  async created() {
    this.bzt = await getVideoImageData({itemName: '平面布置', type: 2})
  },
  methods: {
    slideChange(activeIndex){
      this.activeIndex = activeIndex
      this.audioAutoplay = false
      this.audioSrc = this.bzt[this.activeIndex].mp3
      this.changeAudioStatus()
    },
    changeAudioStatus(){
      var introduceAudio = document.getElementById('introduce-audio')
      if (this.audioAutoplay) {
        this.centerSoundImg = require('@/assets/img/video/sound-stop.png')
        introduceAudio.play()
      }else{
        this.centerSoundImg = require('@/assets/img/video/sound-play.png')
        introduceAudio.pause()
      }
    },
    playAudio() {
      this.audioSrc = this.bzt[this.activeIndex].mp3
      this.audioAutoplay = !this.audioAutoplay
      this.changeAudioStatus()
    }
  }
}
</script>

<style scoped>
.imgs {
  width: 70%;
  height: calc(100% - 20px);
  float: left;
  position: relative;
}

.info{
  width: 30%;
  float: left;
  padding-left: 13px;
  display: table;
  height: 92%;
  margin-top: 30px;
}

.info .title{
  color: #00ffff;
  font-size: 20px;
}
.info .detail {
  color: white!important;
  height: calc(94% - 15px);
  overflow: auto;
  margin-top:15px;
  line-height: 25px;
}

.info .detail::-webkit-scrollbar {
  display:none
}

.info .detail >>> p{
  margin: 0px;
}

.info .content{
  display: table-cell;
  vertical-align: middle;
}
.imgs >>> .swiper-container-horizontal > .swiper-pagination-bullets {
  background: #ccc;
  width: auto;
  margin: 0 auto;
  display: table;
  position: sticky;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 25px;
}
.imgs >>> > div,
.imgs >>> > div > div{
  height: 100%
}
.imgs >>> .swiper-slide {
  background: white;
  text-align: center;
}
#center-sound-img{
  font-size: 60px;
  color: red;
  z-index: 1;
  position: absolute;
  text-align: right;
  cursor: pointer;
  width: 30px;
  top: 10px;
  right: 10px;
  left: auto;
}
</style>