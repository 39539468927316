<template>
<div style="width: 100%;height: 100%;">
  <el-dialog title="播放视频" :visible.sync="playVideoDialog" :before-close="handlePlayVideoClose" :modal="false">
    <video id="sggy-video" :src="sggyVideo" controls="controls" :autoplay="true"></video>
  </el-dialog>
  <div style="width: 100%;height: 100%;" class="image-swiper-wrapper">
    <image-swiper :list="sggy" :slide-change-transition-start="sggySlideChange"/>
    <img id="sggy-play-img" src="../../assets/img/video/play.png" @click="sggyPlayVideo">
  </div>
</div>
</template>

<script>
  import ImageSwiper from '@/components/image-swiper'
  export default {
    components: {
      ImageSwiper
    },
    data() {
      return {
        playVideoDialog: false,
        sggyVideo: '',
        sggyActiveIndex: 0,
        sggy: [{
          name: '清礁工艺',
          url: require('@/assets/img/video/清礁工艺.png'),
          start: 174,
          end: 216
        },{
          name: '疏浚工艺',
          url: require('@/assets/img/video/疏浚工艺.png'),
          start: 217,
          end: 243
        },{
          name: '筑坝工艺',
          url: require('@/assets/img/video/筑坝工艺.png'),
          start: 244,
          end: 265
        },{
          name: '护坡工艺',
          url: require('@/assets/img/video/护坡工艺.png'),
          start: 266,
          end: 287
        },{
          name: '生态工程',
          url: require('@/assets/img/video/生态工程.png'),
          start: 288,
          end: 302
        }]
      }
    },
    created() {
    },
    mounted() {
    },
    methods: {
      sggySlideChange(activeIndex){
        this.sggyActiveIndex = activeIndex
      },
      sggyPlayVideo(){
        this.playVideoDialog = true
        this.sggyVideo = 'http://chaofu-oss.oss-cn-beijing.aliyuncs.com/朝涪Ⅱ标项目介绍.mp4#t='+this.sggy[this.sggyActiveIndex].start+','+this.sggy[this.sggyActiveIndex].end
      },
      handlePlayVideoClose(){
        this.sggyVideo = ''
        this.playVideoDialog = false
      }
    }
  }
</script>

<style scoped>
  #sggy-video {
    width: 100%;
  }
  #sggy-play-img{
    font-size: 60px;
    color: red;
    z-index: 1;
    position: absolute;
    text-align: right;
    cursor: pointer;
    width: 50px;
    height: 50px;
    bottom: 80px;
    right: 80px;
  }
  .image-swiper-wrapper >>> > div,
  .image-swiper-wrapper >>> > div > div {
    height: 100%;
  }
</style>