<template>
  <div style="width: 100%;height: 100%;" class="image-swiper-wrapper">
    <image-swiper :list="list" :slide-change-transition-start="sggySlideChange" />
  </div>
</template>

<script>
  import ImageSwiper from '@/components/image-swiper'
  import { getVideoImageData } from '@/api/video-image.js'
  export default {
    components: {
      ImageSwiper
    },
    props: {
      itemName: {
        type: String,
        default: ''
      },
      sggySlideChange: {
        type: Function,
        default: () => {}
      }
    },
    data() {
      return {
        list: []
      }
    },
    async created() {
      this.list = await getVideoImageData({itemName: this.itemName, type: 2})
    },
    mounted() {
    },
    methods: {
    }
  }
</script>

<style scoped>
  .image-swiper-wrapper >>> > div,
  .image-swiper-wrapper >>> > div > div {
    height: 100%;
  }
</style>