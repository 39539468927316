<template>
  <div class="content">
    <div class="more" v-if="isMore">
      <div class="title">
        {{ moreTitle }}
        <a href="javascript:void(0);" @click="isMore = false;">返回</a>
      </div>
      <div class="more-content">
        <keep-alive>
          <component :is="moreComponent" :item-name="moreTitle" />
        </keep-alive>
      </div>
    </div>
    <el-dialog title="播放视频" :visible.sync="playVideoDialog" :before-close="handlePlayVideoClose">
      <video id="sggy-video" :src="sggyVideo" controls="controls" :autoplay="true"></video>
    </el-dialog>
    <div class="left">
      <div class="screen-item">
        <div class="title">
          单位形象
        </div>
        <div class="img-shuffling player">
          <div class="video-menu">
            <div class="item" v-for="(it, i) in videoData" :key="i" :class="{selected: dwIndex == i}" @click="videoMenu(i)">
              {{ it.name }}
            </div>
            <div class="clear"></div>
          </div>
          <video id="dw-video" :src="dwVideo" controls="controls" :autoplay="dwAutoplay">
            your browser does not support the video tag
          </video>
          <!-- <rtmp-video ref="rtmpVideo" :nowOptions="nowOptions" /> -->
        </div>
      </div>
      <div class="screen-item">
        <div class="title">
          领导关怀
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '领导关怀'; moreComponent = 'CommonCarousel';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="ldgh"/>
        </div>
      </div>
      <div class="screen-item">
        <div class="title">
          单位资质
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '单位资质'; moreComponent = 'CommonCarousel';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="dwzz"/>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="map">
        <a href="javascript:void(0);" @click="isMore = true; moreTitle = '介绍'; moreComponent = 'MiddleIntroduce';">全屏</a>
        <middle-introduce /> 
      </div>
      <div class="screen-item">
        <div class="title">
          核心装备
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '核心装备'; moreComponent = 'CommonCarousel';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="hxzb"/>
        </div>
      </div>
      <div class="screen-item">
        <div class="title">
          精品工程
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '精品工程'; moreComponent = 'CommonCarousel';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="jpgc"/>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="screen-item">
        <div class="title">
          参建单位
        </div>
        <div class="notice">
          <div class="row">
            <div class="unit">建设单位</div>
            <div class="detail">{{ participatingUnits.constructionUnit }}</div>
          </div>
          <div class="row">
            <div class="unit">设计单位</div>
            <div class="detail">{{ participatingUnits.designUnit }}</div>
          </div>
          <div class="row">
            <div class="unit">监理单位</div>
            <div class="detail">{{ participatingUnits.supervisorUnit }}</div>
          </div>
          <div class="row">
            <div class="unit">施工单位</div>
            <div class="detail">{{ participatingUnits.shigongUnit }}</div>
          </div>
        </div>
      </div>
      <div class="screen-item" v-if="rightCenter == 0">
        <div class="title">
          施工工艺
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '施工工艺'; moreComponent = 'SggyImage';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="sggy" :slide-change-transition-start="sggySlideChange"/>
          <img id="sggy-play-img" src="../../assets/img/video/play.png" @click="sggyPlayVideo">
        </div>
      </div>
      <div class="screen-item" v-if="rightCenter == 1">
        <div class="title">
          朝涪1标宣传
        </div>
        <div class="img-shuffling player">
          <div class="video-menu">
            <div class="item" v-for="(it, i) in xcVideoData" :key="i" :class="{selected: xcIndex == i}" @click="xcVideoMenu(i)">
              {{ it.name }}
            </div>
            <div class="clear"></div>
          </div>
          <video id="xc-video" :src="xcVideo" controls="controls" :autoplay="xcAutoplay">
            your browser does not support the video tag
          </video>
        </div>
        <!-- <div class="title">
          朝涪1标宣传
        </div>
        <div class="img-shuffling player">
          <video id="dw-video" src="http://chaofu-oss.oss-cn-beijing.aliyuncs.com/video/20211008%E6%9C%9D%E6%B6%AAI%E6%A0%87%E6%AE%B5%E6%88%90%E5%93%81%EF%BC%88%E7%AC%AC%E4%B8%80%E7%A8%BF%EF%BC%89.mp4" controls="controls" :autoplay="false">
            your browser does not support the video tag
          </video>
          <rtmp-video ref="rtmpVideo" :nowOptions="nowOptions" />
        </div> -->
      </div>
      <div class="screen-item">
        <div class="title">
          取得荣誉
          <a href="javascript:void(0);" @click="isMore = true; moreTitle = '取得荣誉'; moreComponent = 'CommonCarousel';">全屏</a>
        </div>
        <div class="img-shuffling">
          <image-swiper :list="qdry"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageSwiper from '@/components/image-swiper'
  import CommonCarousel from '../more/common-carousel'
  import SggyImage from '../more/sggy-image'
  import { getVideoImageData } from '@/api/video-image.js'
  import MiddleIntroduce from '../more/middle-introduce'
  // import RtmpVideo from '@/components/rtmp-video'

  export default {
    name: 'ImageDisplay',
    components: {
      ImageSwiper,
      CommonCarousel,
      SggyImage,
      MiddleIntroduce
    //  RtmpVideo
    },
    data() {
      return {
        // nowOptions:{
        //   url:'',
        //   type:''
        // },
        isMore: false,
        moreComponent: '',
        activeIndex: 0,
        ldgh: [],
        dwzz: [],
        hxzb: [],
        jpgc: [],
        qdry: [],
        sggy: [{
          name: '清礁工艺',
          url: require('@/assets/img/video/清礁工艺.png'),
          start: 174,
          end: 216
        },{
          name: '疏浚工艺',
          url: require('@/assets/img/video/疏浚工艺.png'),
          start: 217,
          end: 243
        },{
          name: '筑坝工艺',
          url: require('@/assets/img/video/筑坝工艺.png'),
          start: 244,
          end: 265
        },{
          name: '护坡工艺',
          url: require('@/assets/img/video/护坡工艺.png'),
          start: 266,
          end: 287
        },{
          name: '生态工程',
          url: require('@/assets/img/video/生态工程.png'),
          start: 288,
          end: 302
        }],
        dwVideo: '',
        dwAutoplay: false,
        dwIndex: 0,
        videoData: [],

        xcVideo: 'http://chaofu-oss.oss-cn-beijing.aliyuncs.com/video/20211008%E6%9C%9D%E6%B6%AAI%E6%A0%87%E6%AE%B5%E6%88%90%E5%93%81%EF%BC%88%E7%AC%AC%E4%B8%80%E7%A8%BF%EF%BC%89.mp4',
        xcAutoplay: false,
        xcIndex: 0,
        xcVideoData: [{
          url: 'http://chaofu-oss.oss-cn-beijing.aliyuncs.com/video/20211008%E6%9C%9D%E6%B6%AAI%E6%A0%87%E6%AE%B5%E6%88%90%E5%93%81%EF%BC%88%E7%AC%AC%E4%B8%80%E7%A8%BF%EF%BC%89.mp4',
          name: '朝涪1标宣传'
        }, {
          url: 'https://chaofu-oss.oss-cn-beijing.aliyuncs.com/userfiles/2022-01-09/9c70f29de6d44e8286a7dabda7602136/班前五分钟-朝涪段I标.mp4',
          name: '班前五分钟'
        }],

        sggyActiveIndex: 0,
        playVideoDialog: false,
        sggyVideo: '',
        participatingUnits: {},
        rightCenter: 0
      }
    },
    async created() {
      this.$get('tenant/get').then(res => {
        if(res.data.domain.indexOf('yichang') != -1){
          this.rightCenter = 1
        }
      })
      this.$post('participating/list',{}).then(res => {
        this.participatingUnits = (res.data && res.data[0]) || {}
      })
      this.videoData = await getVideoImageData({type: 1})
      if (this.videoData && this.videoData.length > 0) {
        this.dwVideo = this.videoData[0].url
      }
      this.ldgh = await getVideoImageData({itemName: '领导关怀', type: 2})
      this.dwzz = await getVideoImageData({itemName: '单位资质', type: 2})
      this.hxzb = await getVideoImageData({itemName: '核心装备', type: 2})
      this.jpgc = await getVideoImageData({itemName: '精品工程', type: 2})
      this.qdry = await getVideoImageData({itemName: '取得荣誉', type: 2})
      this.initModelVideo()
    },
    methods: {
      handlePlayVideoClose(){
        this.sggyVideo = ''
        this.playVideoDialog = false
      },
      sggyPlayVideo(){
        this.playVideoDialog = true
        this.sggyVideo = 'http://chaofu-oss.oss-cn-beijing.aliyuncs.com/朝涪Ⅱ标项目介绍.mp4#t='+this.sggy[this.sggyActiveIndex].start+','+this.sggy[this.sggyActiveIndex].end
      },
      sggySlideChange(activeIndex){
        this.sggyActiveIndex = activeIndex
      },
      initModelVideo(){
        // this.nowOptions = {
        //   url:'http://chaofu-oss.oss-cn-beijing.aliyuncs.com/%E6%9C%9D%E6%B6%AA%E2%85%A1%E6%A0%87%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8D.mp4',
        //   type:'rtmp/flv'
        // }
        // this.$refs.rtmpVideo.initVideo();
      },
      videoMenu(index) {
        this.dwIndex = index
        this.dwAutoplay = false
        this.dwVideo = this.videoData[index].url
      },
      xcVideoMenu(index) {
        this.xcIndex = index
        this.xcAutoplay = false
        this.xcVideo = this.xcVideoData[index].url
      }
    }
  }
</script>

<style scoped>

  .img-shuffling{
    position: relative;
  }

  .imgs >>> .swiper-container-horizontal > .swiper-pagination-bullets {
    background: #ccc;
    width: auto;
    margin: 0 auto;
    display: table;
    position: sticky;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 25px;
  }

  .imgs >>> > div,
  .imgs >>> > div > div,
  .img-shuffling >>> > div,
  .img-shuffling >>> > div > div {
    height: 100%
  }
  .imgs >>> .swiper-slide {
    background: white;
    text-align: center;
  }

  .imgs {
    width: 650px;
    height: calc(100% - 20px);
    float: left;
    position: relative;
  }

  .content{
    height: 100%!important;
  }

  .content .screen-item{
    width: 461px;
    height: 33%;
    float: left;
  }
  .content .screen-item .title{
    /*background: url(./img/common/top_bg_11.png) no-repeat;*/
    width: 100%;
    height: 22px;
    color: white;
    font-size: 16px;
    text-indent: 30px;
    line-height: 19px;
    border-bottom: 2px solid #044850;
    position: relative;
  }
  .content .screen-item .title:before{
    content: '';
    width: 15px;
    height: 3px;
    position: absolute;
    background: #31FFFF;
    top: 21px;
    left: 54px;
    border-radius: 5px;
  }
  .content .screen-item .title a,
  .middle a{
    float: right;
    text-decoration: none;
    color: #00ffff;
  }
  .middle a{
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .content .left,
  .content .right{
    width: 464px;
    height: 100%;
    float: left;
  }
  .content .left{
    margin-right: 10px;
  }
  .content .left .screen-item{
    /* margin-bottom: 10px; */
  }
  .content .left .screen-item:last-child{
    margin-bottom: 0px;
  }
  .content .middle{
    width: 923px;
    height: 100%;
    float: left;
  }
  .content .middle .map{
    height: 66%;
    /* margin-bottom: 49px; */
    /* background: url(./img/common/map.png); */
    background-size: 100% 100%;
    position: relative;
  }
  .content .right{
    margin-left: 10px;
  }
  .content .right .screen-item{
    /* margin-bottom: 10px; */
  }
  .img-shuffling {
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 20px);
  }
  .img-shuffling img{
    height: 100%;
    margin: 0 auto;
    display: block;
  }
  #dwxx{
    height: 260px;
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .video-menu {
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
    margin: 0 auto;
    text-align: center;
    height: 30px!important;
  }
  .video-menu .item {
    display: inline-block;
    cursor: pointer;
    margin: 0px 5px;
  }
  .video-menu .item.selected {
    color: #00ffff;				
  }
  .clear{
    clear: both;
  }
  #dw-video,
  #xc-video {
    height: 90%;
    margin: 0 auto;
    display: table;
  }
  #sggy-video {
    width: 100%;
  }
  #sggy-play-img{
    font-size: 60px;
    color: red;
    z-index: 1;
    position: absolute;
    text-align: right;
    cursor: pointer;
    width: 30px;
    height: 30px;
    bottom: 30px;
    right: 30px;
  }
  .notice {
    color: #fff;
  }
  .notice .row {
    margin: 10px 0px;
    padding: 0px 20px;
    width: 100%;
    height: 30px;
    margin-top: 30px;
  }
  .notice .row .unit{
    color: #00ffff;
    width: 90px;
    float: left;
    /* border-bottom: .5px solid rgb(111,111,111,.7); */
  }
  .notice .row .detail{
    /* color: rgba(255,255,255,.7); */
    color: white;
    font-size: 14px;
  }
  .content > .more{
    width: calc(100% - 8px);
    height: calc(100% - 88px);
    position: absolute;
    z-index: 11;
    background: url(../../assets/img/common/bg.jpg) no-repeat;
    background-size: 100% calc(100% + 88px);
    background-position-y: -80px;
  }
  .content > .more .more-content {
    height: calc(100% - 30px)
  }
  .more .title {
    width: 100%;
    height: 30px;
    color: white;
    font-size: 14px;
    text-indent: 10px;
    line-height: 30px;
    border-bottom: 1px solid #044850;
    position: relative;
  }
  .more .title{
    padding-left: 10px;
    padding-right: 10px;
  }
  .more .title:before {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    background: #31FFFF;
    top: 28px;
    left: 26px;
    border-radius: 5px;
  }
  .more .title:before {
    left: 36px;
  }
  .more .title a{
    float: right;
    text-decoration: none;
    color: #00ffff;
  }
</style>