<template>
  <div class="container">
    <div class="header">
      <div class="weather">
        <iframe style="margin-top: 10px;" scrolling="no" :src="weatherUrl" frameborder="0" width="120" height="36" allowtransparency="true"></iframe>
      </div>
      <div class="menu">
        <div v-for="(menu, i) in menus" :key="i" class="item" :class="{'selected': menu.selected}" @click="menuClick(i)">
          {{ menu.text }}
        </div>
      </div>
      <div class="title">
        {{title}}
      </div>
      <div class="construction-info">
        <div class="item">
          <div class="row">
            <img src="@/assets/img/header/dingweiweizhi.png" />
            施工地点：
          </div>
          <div class="row">
            {{address}}
          </div>
        </div>
        <div class="item">
          <div class="row">
            <img src="@/assets/img/header/hourglass.png" />
            施工倒计时：
          </div>
          <div class="row">
            {{ countDown }}天
          </div>
        </div>
        <div class="item">
          <div class="time">{{ newestTime }}</div>
          <div class="date-week">
            <div class="date">{{ newestDate }}</div>
            <div class="week">{{ newestWeek }}</div>
          </div>
        </div>
      </div>
      <img src="@/assets/img/common/tuichu.png" class="logout" @click="logout" />
    </div>
    <div class="content">
      <keep-alive>
        <component :is="isComponent" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
  import ImageDisplay from './image-display'
  import ConstructionElements from './construction-elements'
  import VideoWallWrapper from '@/components/Video/video-wall'
  import store from '@/store'
  export default {
    components: { 
      'imageDisplay': ImageDisplay,
      'constructionElements': ConstructionElements,
      VideoWallWrapper : VideoWallWrapper
    },
    data() {
      return {
        isComponent: 'imageDisplay',
        menus: [{
          selected: true,
          text: '形象展示',
          component: 'imageDisplay'
        },{
          text: '施工管理',
          component: 'constructionElements'
        },{
          text: '工地视频',
          component: 'VideoWallWrapper'
        }],
        weatherUrl: '',
        address: '',
        countDown: 0,
        newestTime: '',
        newestDate: '',
        newestWeek: '',
        title: ''
      }
    },
    created() {
      this.$post('common/table/list',{ type: 'project'}).then(res => {
        if(res.data&&res.data[0]){
          let data = res.data[0]
          this.weatherUrl = data.value3;
          this.address = data.value1;
					var s1 = new Date(data.value4);
					var s2 = new Date();//当前日期：2017-04-24
					var days = s1.getTime() - s2.getTime();
					this.countDown = parseInt(days / (1000 * 60 * 60 * 24));
        }
        // this.countDown = res.data.value
      })
    },
    mounted() {
      this.$get('tenant/get').then((res) => {
        this.title = res.data.name
      })
      setInterval(this.setDateTime,1000);
    },
    methods: {
      logout() {
        store.dispatch('user/logout').then(() => {
          location.reload()
        })
      },
      menuClick(i) {
        this.menus.forEach(it => {
          it.selected = false
        })
        this.menus[i].selected = true
        this.isComponent = this.menus[i].component
      },
      setDateTime() {
        var currDate = new Date();
        var currYear = currDate.getFullYear();
        var currMonth = (currDate.getMonth() + 1).toString();
        var currMonthDay = currDate.getDate();
        var currWeekDay = currDate.getDay();
        var currHours = currDate.getHours().toString();
        var currMinutes = currDate.getMinutes().toString();
        var weekday = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
        this.newestTime = (currHours.length == 1 ? '0'+currHours : currHours)+':'+(currMinutes.length == 1 ? '0'+currMinutes : currMinutes);
        this.newestDate = currYear + '-' + (currMonth.length == 1 ? '0'+currMonth : currMonth) + '-' + currMonthDay;
        this.newestWeek = weekday[currWeekDay];
      }
    }
  }
</script>

<style>
body,html,#app{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
  .container{
    padding: 0px 8px 0px 8px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/common/bg.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
  }
  .container .header {
    height: 88px;
  }
  .container .logout {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 25px;
    width: 20px;
    height: 20px;
  }
  .container .content{
    flex: 1;
    height: calc(100% - 88px)
  }
  .container .header .weather{
    width: 140px;
    height: 100%;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    text-align: right;
  }
  .container .header .menu{
    width: 450px;
    height: 100%;
    float: left;
  }
  .container .header .menu .item{
    width: 150px;
    height: 60px;
    text-align: center;
    color: #47BCBC;
    line-height: 60px;
    background: url(../../assets/img/header/menu-not-select-bg.png) no-repeat;
    background-size: 100% 100%;
    float: left;
    cursor: pointer;
    margin-top: 10px;
  }
  .container .header .menu .item:nth-child(2),
  .container .header .menu .item:nth-child(3){
    margin-left: -30px;
  }
  .container .header .menu .item.selected{
    background: url(../../assets/img/header/menu-select-bg.png) no-repeat;
    background-size: 100% 100%;
    color: white;
  }
  .container .header .title{
    float: left;
    background: url(../../assets/img/header/title_02.png) no-repeat;
    width: 785px;
    background-size: 100% 100%;
    height: 80px;
    margin-left: -80px;
    font-size: 26px;
    color: white;
    text-align: center;
    line-height: 80px;
  }
  .container .header .construction-info{
    width: 599px;
    height: 60px;
    background: url(../../assets/img/header/construction-info-bg.png) no-repeat;
    margin-left: -32px;
    float: left;
    margin-top: 10px;
    display: flex;
    background-size: 100% 100%;
  }
  .container .header .construction-info .item{
    flex: 1;
    color: white;
    box-sizing: border-box;
    padding-top: 10px;
    font-size: 14px;
  }
  .container .header .construction-info .item img{
    vertical-align: middle;
    margin-right: 10px;
    width: 12px;
  }
  .container .header .construction-info .item:nth-child(1){
    padding-left: 60px;
  }
  .container .header .construction-info .item:nth-child(2){
    /* padding-left: 60px; */
  }
  .container .header .construction-info .item .row:nth-child(2){
    margin-left: 25px;
  }
  .container .header .construction-info .item .time{
    font-size: 20px;
    padding-top: 5px;
    float: left;
  }
  .container .header .construction-info .item .date-week{
    padding-left: 10px;
    float: left;
  }
  
</style>