import request from '@/utils/request'

export async function getVideoImageData(params) {
  var result = []
  await request({
    url: 'video/image/list',
    method: 'get',
    params
  }).then(res => {
    const { data } = res
    result = data
  })
  return result
}

